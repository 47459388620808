import React, { useState } from 'react';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <a href='/'>
          <img src="/royyzRADO1.jpg" alt="Logo" className="navbar-logo" />
        </a>
      </div>
      <button className={`navbar-toggle ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </button>
      <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <li className="navbar-item"><a href="/">Services</a></li>
        <li className="navbar-item"><a href="/">Solutions</a></li>
        <li className="navbar-item"><a href="/">Industries</a></li>
        {/* <li className="navbar-item"><a href="https://qms-ghalamedicalclinic.netlify.app/spwu">About</a></li> */}
        <li className="navbar-item"><a href="/AboutUs">About</a></li>
        <li className="navbar-item"><a href="/">Careers</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
