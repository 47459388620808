import React from 'react';
import './AboutUs.css';

const AboutUsPage = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-us-container">
        <div className="about-us-header">
          <h1 className="title2">
            Powering Industries Through Technology
          </h1>
          {/* <p className="header-subtitle">
            Cutting-edge technology solutions tailored for diverse business sectors.
          </p> */}
        </div>
        
        <div className="about-us-content">
          <p className="large-text">
            We deliver state-of-the-art technology solutions that transform businesses 
            across multiple industries, from manufacturing to retail, healthcare to finance.
          </p>

          <div className="content-section">
            <p>
              Our industry-adaptive solutions leverage the latest technologies to 
              address unique sector-specific challenges. Whether it's AI-driven 
              analytics, cloud solutions, or process automation, we customize our 
              technology to fit your industry's specific needs.
            </p>
            
            <p>
              With deep expertise across multiple business sectors, we understand 
              the distinct requirements of different industries. This allows us to 
              deliver solutions that not only solve current challenges but also 
              anticipate future industry trends.
            </p>
            
            <p>
              By combining cutting-edge technology with industry-specific knowledge, 
              we enable businesses to stay competitive in their respective sectors. 
              Our solutions evolve with your industry, ensuring long-term success 
              and sustainable growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;